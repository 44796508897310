.ButtomAction{
    background-color: #FF3141;
    border-radius: 10px;
    margin: 15px 25px 15px 25px;
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    font-weight: bolder;
    color: white;
    padding: 12px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.fitnessatlasIcon{
    height: 45px;
    width: 150px;
}

.HomeTitle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.HomeCard{
    background-color: #FEFEFE;
    border-radius: 10px;
    margin: 15px 25px 0px 25px;
    display: flex;
    padding: 40px 20px 40px 20px;
}

.HomeInfoState{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 50px;
}


.StateText{
    display: inline-block;
    font-size: 0.3em;
    color: #1F7BF5;
    font-weight: bolder;
    transform: scale(0.85);
}

.HomeInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
}

.HomeInfoName{
    font-weight: bolder;
    font-size: 1.5em;
}

.StateCheckMark{
    height: 15px;
    width: 15px;
}


.HomeCardWithTitle{
    background-color: #FEFEFE;
    border-radius: 10px;
    margin: 15px 25px 0px 25px;
    display: flex;
    flex-direction: column;
}

.HomeCardWithTitle.Disabled{
    color: gray !important;
    background-color: #ececec;
    opacity: 0.5;
    pointer-events:none;
}

.CardTitleBar{
    display: flex;
    padding: 12px;
    border-bottom: solid #EBEBEB 1px;
    align-items: center;
}

.CardIcon{
    height: 15px;
    width: 15px;
    margin-left: 5px;
}

.CardTitle{
    margin-left: 5px;
    font-weight: bold;
}

.CardAction{
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 1.1em;
    font-weight: bold;
    color: #2481FC;
}

.CardAction.Inline{
    padding: 0px;
}

.SchoolInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.GradeLabel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.GradeInfo{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-Right: 10px;
}

.InviteCode{
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.5em;
    font-weight: bolder;
    margin-Right: 10px;
}

.SchoolInfoText{
    padding: 10px;
}

.ButtomActionPrimary{
    background-color: #0091ff;
    border-radius: 10px;
    margin: 15px 25px 15px 25px;
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    font-weight: bolder;
    color: white;
    padding: 12px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.HomeBase{
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.UploadInput{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}



.adm-form-item-child-position-normal > * {
    flex-direction: row !important;
    display: flex !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.fitnessatlasIcon{
  height: 45px;
  width: 150px;
}

.HomeTitle{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.HomeCard{
  background-color: #FEFEFE;
  border-radius: 10px;
  margin: 15px 25px 0px 25px;
  display: flex;
  padding: 40px 20px 40px 20px;
}

.HomeInfoState{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 50px;
}


.StateText{
  display: inline-block;
  font-size: 0.3em;
  color: #1F7BF5;
  font-weight: bolder;
  transform: scale(0.85);
}

.HomeInfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.HomeInfoName{
  font-weight: bolder;
  font-size: 1.5em;
}

.StateCheckMark{
  height: 15px;
  width: 15px;
}


.HomeCardWithTitle{
  background-color: #FEFEFE;
  border-radius: 10px;
  margin: 15px 25px 0px 25px;
  display: flex;
  flex-direction: column;
}

.HomeCardWithTitle.Disabled{
  color: gray !important;
  background-color: #ececec;
  opacity: 0.5;
  pointer-events:none;
}

.CardTitleBar{
  display: flex;
  padding: 12px;
  border-bottom: solid #EBEBEB 1px;
  align-items: center;
}

.CardIcon{
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.CardIcon.TextIcon{
  height: 15px;
  width: 15px;
  margin-left: 5px;
  font-weight: bolder;
  color: #2481FC;
}


.CardTitle{
  margin-left: 5px;
  font-weight: bold;
}

.CardAction{
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 1.1em;
  font-weight: bold;
  color: #2481FC;
}

.CardAction.Disable{
  color: #606060;
}

.CardAction.Inline{
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SchoolInfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.GradeLabel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.GradeInfo{
  justify-content: center;
  align-items: center;
  display: flex;
  margin-Right: 10px;
}

.InviteCode{
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.5em;
  font-weight: bolder;
  margin-Right: 10px;
}

.SchoolInfoText{
  padding: 10px;
}

.ButtomAction{
  background-color: #FF3141;
  border-radius: 10px;
  margin: 15px 25px 15px 25px;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  font-weight: bolder;
  color: white;
  padding: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.ButtomAction.Primary{
  background-color: #0091ff;
}

.ButtomAction.Default{
  background-color: white;
  border: solid #0091ff 2px;
  color: #0091ff;
}


.HomeBase{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
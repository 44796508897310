.tb-header{
    padding: 5px 15px;
    white-space: nowrap;
    font-weight: 800;
}

.tb-item{
    padding: 5px;
    white-space: nowrap;
}

.tb-card{
    padding: 5px;
    overflow-x: scroll;
}

.term-selector-base{
    width: 100%;
    justify-content: center;
    align-items: center;
}

.term-selector{
    display: flex;
    margin: 10px;
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
}